/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages

        var slideoutPadding;
        var ssm = window.matchMedia("(max-width:320px)");
        if(ssm.matches) {
          slideoutPadding = 260;
        } else {
          slideoutPadding = 290;
        }

        var slideout = new Slideout({
            'panel': document.getElementById('main-wrapper'),
            'menu': document.getElementById('menu'),
            'padding': slideoutPadding,
            'tolerance': 70,
            'side':'left',
            'touch': false
        });

        slideout.on('beforeopen', function() {
          document.querySelector('.fixed').classList.add('fixed-open');
          $('footer.content-info').addClass('fixed-open');
          $('.project-teaser').addClass('fixed-open');
        });

        slideout.on('beforeclose', function() {
          document.querySelector('.fixed').classList.remove('fixed-open');
          $('footer.content-info').removeClass('fixed-open');
          $('.project-teaser').removeClass('fixed-open');
          $('#main-wrapper').removeClass('darken');
        });

        slideout.on('open', function() {
          $('#main-wrapper, .project-teaser').addClass('darken');
        });

        $('.js-slideout-toggle').click(function(){
          $('#main-wrapper, .project-teaser').toggleClass('slid');
          slideout.toggle();
          $(this).toggleClass('active');
        });

        // search
        function initSearch() {
          $('.sb-icon-search').on('click', function(e) {
            e.preventDefault();
            $(this).parents('.sb-search').addClass('sb-search-open');
            $('.navbar-nav').addClass('sb-search-active');
            $('.sb-search-input').focus();
            $('.sb-search-input').keypress(function (e) {
              if (e.which === 13) {
                $(this).parents('form').submit();
                return false;
              }
            });
          });
          $('.sb-search-close').on('click', function(e) {
            e.preventDefault();
            $('.sb-search-input').blur();
            $(this).parents('.sb-search').removeClass('sb-search-open');
            $('.navbar-nav').removeClass('sb-search-active');
          });
        }

        initSearch();

        $('.search-results .posts-navigation a').addClass('btn-darker');

        $('.entry-content table').addClass('table').wrap('<div class="table-responsive"></div>');

        // slick slider
        $('.tile-slider').on('setPosition', function(event, slick){

            var counterID = $(this).data('counter');

            $('#' + counterID+' .counter').html(Math.ceil(slick.currentSlide/slick.options.slidesToShow) + 1 +' / '+Math.ceil(slick.slideCount/slick.options.slidesToShow));

        });

        $('.tile-slider').each(function (idx, item) {

            var carouselId = "carousel_" + idx;
            this.id = carouselId;
            $(this).attr('data-counter','carousel_slick-control_' + idx);
            $(this).next().attr('id','carousel_slick-control_' + idx);

            $(this).slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 0,
                appendArrows: $(this).next()
            });


        });

        $('.emissary-slider, .tile-slider').on('setPosition', function(event, slick){

            var counterID = $(this).data('counter');
            $('#' + counterID+' .counter').html(Math.ceil(slick.currentSlide/slick.options.slidesToShow) + 1 +' / '+Math.ceil(slick.slideCount/slick.options.slidesToShow));
            if (slick.slideCount <= slick.options.slidesToShow) {
              $('#' + counterID).hide();
              //console.log("counterID: " + counterID)
            }
        });


        $('.emissary-slider').each(function (idx, item) {

            var carouselId = "carousel_" + idx;
            this.id = carouselId;
            $(this).attr('data-counter','carousel_slick-control_' + idx);
            $(this).next().attr('id','carousel_slick-control_' + idx);

            $(this).slick({
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                appendArrows: $(this).next(),
                responsive: [
                  {
                        breakpoint: 992,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                        }
                  },
                  {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2
                        }
                  },
                  {
                        breakpoint: 480,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1
                        }
                  }
                ]
            });

        });


        function renderCounter(slick,carouselId) {
          var slidesToShow;
          if(slick.activeBreakpoint!==null){
            slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
          } else {
            slidesToShow = slick.options.slidesToShow;
          }

          if($('#'+carouselId).parents('.slider-list').find('.slick-next').length>0){
            $('#'+carouselId).parents('.slider-list').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' / ' + Math.ceil((slick.slideCount)/slidesToShow));
            $('#'+carouselId).parents('.slider-list').find('.slick-control').show();
          } else {
            $('#'+carouselId).parents('.slider-list').find('.counter').html('');
            $('#'+carouselId).parents('.slider-list').find('.slick-control').hide();
          }
        }

        $('.common-slider').each(function (idx, item) {
          if ($(this).hasClass('slick-initialized')) {
              $(this).slick('resize');
          } else {
            var sliderId = "slider_" + idx;
            this.id = sliderId;
            $(this).attr('data-counter','slider_slick-control_' + idx);
            $(this).next('.slick-control').attr('id','slider_slick-control_' + idx);

            $(this).on('init', function(event, slick) {
              //console.log('init');
            });

            $(this).on('reInit', function(event, slick){
              //console.log("reInit");
            });

            $(this).on('setPosition', function(event, slick) {
              //console.log('setPosition');
              renderCounter(slick,sliderId);
            });

            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
              //console.log(nextSlide);
            });

            $(this).on('afterChange', function(event, slick, currentSlide){
              //console.log(currentSlide);
            });

            $(this).on('edge', function(event, slick, direction){
              //console.log('edge was hit');
            });

            $(this).slick({
              appendArrows: $('#slider_slick-control_' + idx)
            });

          }
        });

        $('.info-slider').each(function (idx, item) {

            var carouselId = "carousel_" + idx;
            this.id = carouselId;
            $(this).attr('data-counter','carousel_slick-control_' + idx);
            $(this).next().attr('id','carousel_slick-control_' + idx);

            $(this).on('setPosition', function(event, slick) {
              //console.log('setPosition');
              renderCounter(slick,carouselId);
            });

            $(this).slick({
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 6,
                appendArrows: $(this).next(),
                responsive: [
                  {
                        breakpoint: 992,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                        }
                  },
                  {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2
                        }
                  },
                  {
                        breakpoint: 480,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2
                        }
                  }
                ]
            });

        });

        $('#newsletterform').validate({
            rules: {
                terms: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                }
            },
            highlight: function(element) {
                $(element).closest('.form-group.has-feedback').addClass('has-error');
                $(element).parent().find('.form-control-feedback').addClass('glyphicon-remove');
            },
            unhighlight: function(element) {
                $(element).closest('.form-group.has-feedback').removeClass('has-error').addClass('has-success');
                $(element).parent().find('.form-control-feedback').removeClass('glyphicon-remove').addClass('glyphicon-ok');
            },
            errorElement: 'span',
            errorClass: 'help-block',
            errorPlacement: function(error, element) {
                if(element.parent('.input-group').length) {
                    error.insertAfter(element.parent());
                } else {
                    error.insertAfter(element);
                }
            },
            success: function(element) {
              //$(element).closest('.form-group').removeClass('has-error').addClass('has-success');
              //$(element).parent().find('.form-control-feedback').removeClass('glyphicon-remove').addClass('glyphicon-ok');
            }
        });

        $('.accordion').each(function () {
          $(this).find('h3').each(function () {
              $(this).prepend($('<span>').addClass('icon').addClass('icon-plus'));
              $(this).click(function (event) {
                  event.preventDefault();
                  var $h3 = $(event.target);
                  var $div = $(event.target).next('div');

                  $h3.find('.icon').toggleClass('icon-plus').toggleClass('icon-minus');

                  if (!$div.is(':visible')) {
                      $div.slideDown(250);
                  } else {
                      $div.slideUp(150);
                  }
              });
          });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      },
      resize: function() {

        function equalHeight(group) {
          var tallest = 0;
          group.css('height','auto'); //otherwise only works once!
          group.each(function() {
            var thisHeight = $(this).height();
            if(thisHeight > tallest) {
              tallest = thisHeight;
            }
          });
          group.height(tallest);
        }

        var xs = window.matchMedia("(max-width: 480px)");
        var sm = window.matchMedia("(max-width: 768px)");
        var md = window.matchMedia("(max-width: 992px)");
        var lg = window.matchMedia("(max-width: 1200px)");

        var minWidth = window.matchMedia("(min-width: 768px)");
        var minheight = window.matchMedia("(min-height: 620px)");

        var mainHeight = $(window).height();
        var headerHeight = $('.banner').height();

        if(sm.matches && minheight.matches) {
            mainHeight = $(window).height()*0.7;
        }
        if(xs.matches && minheight.matches) {
            mainHeight = $(window).height()*0.65;
        }

        if(minWidth.matches) {
            var rowHeight = (mainHeight - headerHeight)/2;
            // $('.home .tile').css({height:rowHeight*1.2});

            timeout = setTimeout(function(){
              var blogListHeight = $('.blog-list').outerHeight();
              var columnListHeight = $('.column-list').outerHeight();
              var tipsListHeight = $('.tips-list').outerHeight();
              var pressListHeight = $('.press-list').outerHeight();

              if ((blogListHeight + columnListHeight + tipsListHeight) >= pressListHeight) {
                var newPressHeight = blogListHeight + columnListHeight + tipsListHeight;
                $('.press-list').css({height:newPressHeight+20});
              } else {
                var newColumnHeight = pressListHeight - blogListHeight;
                $('.column-list').css({height:newColumnHeight-10});
              }

            }, 300);


        } else {
          $('.blog-list').css({height:'auto'});
          $('.column-list').css({height:'auto'});
          $('.press-list').css({height:'auto'});
        }

        //$('#main-wrapper').css({minHeight:mainHeight - headerHeight});

        equalHeight($(".initiative-text"));
        //equalHeight($(".info-name"));

          $('.info-slider').each(function() {
            var kids = $(".info-name").children();
            equalHeight(kids);
          });

          $('.info-teaser-image-wrap').each(function() {
            var thisWidth = $(this).width();
            $(this).css({height:thisWidth});
          });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_contact_php': {
      init: function() {

        var ecarfMarker = L.icon({
          iconUrl: '/wp-content/themes/ecarf/dist/images/mapicon.svg',
          iconSize: [70, 70], // size of the icon
          iconAnchor: [35, 70], // point of the icon which will correspond to marker's location
        });

        var mobileBreakpoint = window.matchMedia("(max-width: 768px)");

        var map = new L.Map('map', {
          center: new L.LatLng(52.527402,13.378466),
          zoom: 17,
          touchZoom: true,
          tap: false,
          dragging: true,
          scrollWheelZoom: false,
          boxZoom: true,
          zoomControl: true,
          attributionControl: false
        });

        if(mobileBreakpoint.matches) {
          map.zoomControl.setPosition('bottomleft');
        } else {
          map.zoomControl.setPosition('bottomright');
        }
        var googleLayer = new L.Google('ROADMAP');
        map.addLayer(googleLayer);
        L.marker([52.527402,13.378466], {
          icon: ecarfMarker
        }).addTo(map);

      },
      resize: function() {

      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_uber': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the about us page
        // slick slider
        $('.aboutus-slider .slider').on('setPosition', function(event, slick){
          var counterID = $(this).data('counter');
          // $('#' + counterID +' .counter').html(Math.ceil(slick.currentSlide / slick.options.slidesToShow) + 1 +' / ' + Math.ceil(slick.slideCount/slick.options.slidesToShow));
        });

        $('.aboutus-slider .slider').each(function (idx, item) {

          var carouselId = "carousel_" + idx;
          this.id = carouselId;
          $(this).attr('data-counter','carousel_slick-control_' + idx);
          $(this).next().attr('id','carousel_slick-control_' + idx);
          $(this).slick({
            dots: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            appendArrows: $(this).next(),
            infinite: true,
            speed: 300,
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 3000
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).resize(UTIL.resizeEvents);

})(jQuery); // Fully reference jQuery after this point.
